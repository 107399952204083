import React from 'react';

const Style = {
  backgroundColor: '#697364',
  borderRadius: 20,
  padding: '4px 12px 5px',
  fontSize: 14,
  color: 'white',
  fontWeight: '500',
};

const Category = ({ name }) => (
  <span className='category' style={Style}>
    {name}
  </span>
);

export default Category;
