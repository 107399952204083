import React from 'react';

import Image from 'gatsby-image';
import StandardAvatar from '/src/images/brand/standard-avatar.png';
import './author.scss';

const Author = ({ img, name, email }) => (
  <div className='author'>
    {img ? (
      <Image className='avatar' fixed={img} alt='' />
    ) : (
      <img className='avatar' src={StandardAvatar} alt='' />
    )}
    <div className='information'>
      <p>{name}</p>
      {email ? (
        <>
          <a href={`mailto:` + email}>{email}</a>
        </>
      ) : (
        ''
      )}
    </div>
  </div>
);

export default Author;
