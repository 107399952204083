import React from 'react';

import Category from '../categories';
import Author from '../author';
import Wrapper from '../../layout/wrapper';
import './content.scss';

const Content = ({ content, title, categories, author }) => (
  <>
    <Wrapper width={1024} center>
      <header className='article-header'>
        {title && (
          <h1 className='display' itemProp='headline'>
            {title}
          </h1>
        )}
        {author && (
          <div className='post-information'>
            <Author
              img={author.fields.img.lf.cis.f}
              name={author.title}
              email={author.fields.ci.email}
            />
            {categories && (
              <div className='categories'>
                {categories.nodes.map((category) => (
                  <Category name={category.name} />
                ))}
              </div>
            )}
          </div>
        )}
      </header>
      <main
        itemProp='articleBody'
        id='content'
        dangerouslySetInnerHTML={{ __html: content }}
      ></main>
    </Wrapper>
  </>
);

export default Content;
