import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/layout/layout';
import Content from '../components/articles/content';
import SEO from '../components/seo';

export default function Article({ data }) {
  const post = data.post;
  const featuredImage = {
    fluid: post.fi?.node.lf.cis.fl || '',
    alt: post.fi?.node.alt || '',
  };

  return (
    <>
      <Layout>
        <SEO title={post.title} description={post.seo.metaDesc} />
        <article
          className='article-single'
          itemScope
          itemProp='http://schema.org/Article'
        >
          {featuredImage && (
            <Image
              className='article-main-img'
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              loading='eager'
            />
          )}
          <Content
            title={post.title}
            categories={post.categories}
            content={post.content}
            author={post.authorEmployee.author}
          />
        </article>
      </Layout>
    </>
  );
}

export const ARTICLEQUERY = graphql`
  query MQ($id: String) {
    post: wpArticles(id: { eq: $id }) {
      authorEmployee {
        author {
          ... on WpEmployee {
            id
            title
            fields: employeeFields {
              ci: contactinformation {
                email
              }
              img: image {
                lf: localFile {
                  cis: childImageSharp {
                    f: fixed(width: 48, height: 48) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      excerpt
      date(formatString: "DD.MM.YYYY")
      title
      slug
      content
      seo {
        metaDesc
      }
      categories {
        nodes {
          name
        }
      }
      fi: featuredImage {
        node {
          lf: localFile {
            cis: childImageSharp {
              fl: fluid(toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
